import { app, db, functions } from '..';
import {
  sendStageEnd,
  sendStageScore,
  sendStageStart,
} from '@riddler-co-jp/specc-auth-client';
import { DocumentReference, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import {
  DocumentDataHook,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

export type SingleState = {
  finished: boolean[];
  answers: string[];
};

export type Game1 = {
  answers: SingleState[];
  totalScore: number;
};

export type Stats = {
  rate: {
    rate: number[];
  }[];
  averageScore: number;
};

type Maybe<T> = T | null;

const stageId = 'ct2switch';

export const startGame1 = (eventId: string, uid: string) =>
  sendStageStart(app, eventId, uid, stageId);

export const submitGame1 = (eventId: string, uid: string, result: Game1) => {
  const recordSubmissions = httpsCallable(functions, 'recordSubmissions');
  return Promise.allSettled([
    recordSubmissions({ eventId, result }),
    sendStageScore(app, eventId, uid, stageId, result.totalScore),
  ]);
};

export const finishGame1 = (eventId: string, uid: string) =>
  sendStageEnd(app, eventId, uid, stageId);

export const useStage1SubmissionDocData = (
  eventId?: Maybe<string>,
  userId?: Maybe<string>
): DocumentDataHook<Game1> => {
  const docRef =
    eventId != null && userId != null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          'users',
          userId
        ) as DocumentReference<Game1>)
      : undefined;
  return useDocumentData<Game1>(docRef);
};

export const useStatDocData = (
  eventId?: Maybe<string>
): DocumentDataHook<Stats> => {
  const docRef =
    eventId != null
      ? (doc(db, 'version/1/stats', eventId) as DocumentReference<Stats>)
      : undefined;
  return useDocumentData<Stats>(docRef);
};
