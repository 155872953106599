export const rankBorder = [
  [2, 4, 6, 8, 10, 13], // Stage1
  [5, 9, 12, 15, 18, 21], // Stage2
  [2, 4, 8, 12, 16, 20], // Stage3
  [20, 30, 40, 50, 60, 70], // Stage4
  [8, 10, 12, 14, 16, 18], // Stage5
];

export function calcRank(score: number, idx: number): number {
  if (idx < 0 || 4 < idx) return -1;
  if (score < 0) return -1;
  return rankBorder[idx].reduce(
    (rank, border, idx) => (rank === 6 && score < border ? idx : rank),
    6
  );
}

const f = (v: number) => {
  if (v <= 0.5) return Math.pow(v, 1.2);
  return 1 - Math.pow(1 - v, 1.2);
};

export const normalize = (v: number) => {
  if (v < 30) return 1 / 5;
  if (70 <= v) return 1;
  return f((v - 30) / 40) * 0.8 + 0.2;
};

export const Rank = ['C', 'C+', 'B', 'B+', 'A', 'A+', 'S'] as const;
export type Rank = (typeof Rank)[number];

export const getRankMessage = (rank: number): Rank => Rank[rank] ?? '-';
export const calcRankMessage = (score: number, idx: number): Rank =>
  getRankMessage(calcRank(score, idx));

export type DataArray = [number, number, number, number, number];
