import { startGame1 } from '../../../api/game';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import StageTitle from '../../../static/png//Stage_title.png';
import RuleImg from '../../../static/png/rule.png';
import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MainButton as _Button,
  CommonBG,
} from '@riddler-co-jp/specc-ui-components';
import { Mission } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

interface StartPageProps {
  onBack?: () => void;
}

const StartPage: React.FC<StartPageProps> = props => {
  const navigate = useNavigate();
  const goTo = (url: string) => {
    navigate(url);
  };
  const { eventId } = useParams<'eventId'>();
  const user = useCurrentUser();
  const onBack = React.useCallback(() => {
    window.location.href =
      'https://specc-dev.riddler.co.jp/events/' + eventId + '/stages';
  }, [eventId]);

  return (
    <CommonBG>
      <Rule>
        <Title src={StageTitle} />
        <Mission>多角的な思考で法則問題を解きなさい。</Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 8分
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <Description>
          <RuleThumb src={RuleImg} />
          <h2>ルール</h2>
          <ul>
            <li>
              文字やイラストなどが、<b>とある法則で並べられたナゾ</b>
              が出題されます。法則を推理し、答えを入力しましょう。
            </li>
            <li>
              問題は4問で1セットになっており、同じセットの中では似たような問題が出題されます。しかし、見た目は似ていても法則は問題ごとに異なります。
            </li>
            <li>
              問題は全部で4セット（16問）あります。どの順番で解答をしてもかまいません。また、解答しない問題があっても特にペナルティなどはありません。
            </li>
            <li>
              制限時間終了後に解答判定を行います。正解数に応じて得点が与えられます。
            </li>
          </ul>
        </Description>
        <Buttons>
          <Button color='negative' size='large' onClick={() => onBack()}>
            戻る
          </Button>
          <Button
            color='positive'
            size='large'
            disabled={eventId === undefined || user?.uid == null}
            onClick={() => {
              if (eventId === undefined || user?.uid == null) return;
              startGame1(eventId, user.uid);
              goTo(`/events/${eventId}/countdown`);
            }}
          >
            スタート
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const RuleThumb = styled.img`
  width: 100%;
  height: auto;
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  max-width: 800px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Description = styled.div`
  padding: 2rem;
  font-size: 1.6rem;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

export default StartPage;
