import { app } from '.';
import Loading from './components/eventProvider/common/Loading';
import CountDown from './components/eventProvider/stagePages/CountDown';
// import Game from './components/eventProvider/stagePages/Game';
// import StartPage from './components/eventProvider/stagePages/StartPage';
import { useSignIn } from '@riddler-co-jp/specc-auth-client';
import React, { FC } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useParams,
} from 'react-router-dom';

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }

  return <>{props.children}</>;
};

export const TrainingRouter = () => {
  const { trainingId } = useParams<'trainingId'>();
  const match = useMatch('/:trainingId/*');
  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: true,
        redirectPathOnFail: '/auth-error',
      },
    ];
  }, []);

  const handleBeforeUnloadEvent = (event: BeforeUnloadEvent): void => {
    event.returnValue =
      'このページを再読み込みしますか？入力した内容は保存されません。';
  };

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
  }, []);

  const onExit = (url: string) => {
    window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
    window.location.href = url;
  };

  return (
    <Routes>
      {/* communicate1 */}
      {/*
      <Route path='/events/:eventId/' element={<StartPage />}></Route>
      <Route path='/events/:eventId/countdown' element={<CountDown />}></Route>
      */}
    </Routes>
  );
};
