import { stages } from '../../../lib/Problems';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import { useTimer } from '../../../redux/selectors/timeSelectors';
import ExampleA from '../../../static/jpg/1_example.jpg';
import ExampleB from '../../../static/jpg/2_example.jpg';
import ExampleC from '../../../static/jpg/3_example.jpg';
import ExampleD from '../../../static/jpg/4_example.jpg';
import AnsweredImg from '../../../static/png/answered.png';
import ButtonA from '../../../static/png/button_a.png';
import ButtonB from '../../../static/png/button_b.png';
import ButtonC from '../../../static/png/button_c.png';
import ButtonD from '../../../static/png/button_d.png';
import TimeGauge from '../../uiElements/TimeGauge';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CommonBG,
  Input,
  SubButton,
  MainButton as _Button,
  TextWindow as _TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useStatePersist as useState } from 'use-state-persist';

interface GameProps {}

const duration = 8 * 60;

export class SingleState {
  finished: boolean[];
  answers: string[];

  constructor() {
    this.finished = [false, false, false, false];
    this.answers = ['', '', '', ''];
  }
}

export class SingleStateAnswer {
  finished: boolean[];
  answers: string[];
  rates: number[];

  constructor() {
    this.finished = [false, false, false, false];
    this.answers = ['', '', '', ''];
    this.rates = [0, 0, 0, 0];
  }
}

interface SendButtonProps {
  answered: boolean;
  disabled: boolean;
  onReset: () => void;
  onSend: () => void;
}

const SendButton: React.FC<SendButtonProps> = ({
  answered,
  disabled,
  onReset,
  onSend,
}) => {
  if (answered) {
    return (
      <_Button color='negative' size='large' onClick={() => onReset()}>
        訂正
      </_Button>
    );
  }

  return (
    <_Button
      disabled={disabled}
      color='positive'
      size='large'
      onClick={() => onSend()}
    >
      送信
    </_Button>
  );
};

const Game: React.FC<GameProps> = () => {
  const { eventId } = useParams<'eventId'>();
  const uid = useCurrentUser()?.uid;
  const localRecordName = (name: string) => `${eventId}-${uid}-${name}`;
  const [phase, setPhase] = useState(localRecordName('phase'), 0);
  const [selected, setSelected] = useState(localRecordName('selected'), 0);
  const [question, setQusetion] = useState(localRecordName(`question`), 0);
  const [inputStr, setInputStr] = useState<string>(
    localRecordName(`inputStr`),
    ''
  );
  const [questionOpacity, setQusetionOpacity] = useState(
    `${eventId}-questionOpacity`,
    1
  );
  const examples = [ExampleA, ExampleB, ExampleC, ExampleD];
  const buttonImages = [ButtonA, ButtonB, ButtonC, ButtonD];
  const cat = ['A', 'B', 'C', 'D'];

  const [state, setState] = useState<SingleState[]>(localRecordName('state'), [
    new SingleState(),
    new SingleState(),
    new SingleState(),
    new SingleState(),
  ]);

  const timer = useTimer();
  const navigate = useNavigate();
  const goTo = (url: string, params: any) => {
    navigate(url, { state: params });
  };

  const send = () => {
    if (inputStr == '') return;

    let fixed = inputStr;
    // 全角を半角に変換
    fixed = fixed.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });

    // 小文字を大文字に変換
    fixed = fixed.toUpperCase();

    // ひらがなをカタカナに変換]
    fixed = fixed.replace(/[ぁ-ん]/g, s => {
      return String.fromCharCode(s.charCodeAt(0) + 0x60);
    });

    const st = state;
    st[selected].finished[question] = true;
    st[selected].answers[question] = fixed;

    setState([...st]);
    setInputStr(fixed);
  };

  const reset = () => {
    const st = state;
    st[selected].finished[question] = false;
    st[selected].answers[question] = '';

    setState([...st]);
    setInputStr('');
  };

  // タイムアップ
  const onFinish = React.useCallback(() => {
    goResult();
  }, []);

  const goResult = () => {
    goTo(`/events/${eventId}/finish`, state);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return;
    send();
  };

  return (
    <CommonBG style={{ minWidth: 'initial' }}>
      <Wrapper>
        <TimeGauge
          duration={duration}
          onFinished={onFinish}
          timerKey={`ct2switch-${eventId}-${uid}`}
        />

        <Screens phase={phase}>
          <Screen>
            <Desc>
              {/*
              <SubButton
                color='negative'
                size='medium'
                onClick={() => goResult()}
              >
                リザルトへ（デバッグ）
              </SubButton>
              */}
              問題セットを選択してください
            </Desc>

            <SetSelect>
              <div>
                {examples.map((single, n) => {
                  return (
                    <SingleSet
                      selected={selected == n}
                      onClick={() => {
                        setSelected(n);
                      }}
                      key={`set-${n}`}
                    >
                      <div>
                        <p>{cat[n]}</p>
                      </div>
                      <ButtonImg>
                        <img src={buttonImages[n]} />
                      </ButtonImg>
                      <ul>
                        {state[n].finished.map((single, i) => {
                          return (
                            <Dot key={`stage-${cat[n]}${i}`} finished={single}>
                              {single}
                              <div>{i + 1}</div>
                              {single && <FontAwesomeIcon icon={faCheck} />}
                            </Dot>
                          );
                        })}
                      </ul>
                    </SingleSet>
                  );
                })}
              </div>
              <div>
                <div>
                  <TextWindow bracket style={{ height: 120 }}>
                    <div>
                      {selected == 0 && (
                        <>
                          <p>
                            あるルールに従って、○の中に数字が書かれています。
                          </p>
                          <p>？に入る数字を答えてください。</p>
                        </>
                      )}
                      {selected == 1 && (
                        <>
                          <p>共有した特徴を持つアルファベットが、</p>
                          <p>アルファベット順に並べられています。</p>
                          <p>？に入る文字を答えてください。</p>
                        </>
                      )}
                      {selected == 2 && (
                        <>
                          <p>
                            イラストがある特徴によってA・B２つのグループに分かれています。
                          </p>
                          <p>
                            ４つの選択肢のうち３つはAに、１つはBに入ります。
                          </p>
                          <p>Bに入るものを答えてください。</p>
                        </>
                      )}
                      {selected == 3 && (
                        <>
                          <p>
                            あるルールに従ってマスの中に文字や図形が入っています。
                          </p>
                          <p>？に入るものを選択肢の中から選んでください。</p>
                        </>
                      )}
                    </div>
                  </TextWindow>
                  <Example>
                    <div>
                      <img src={examples[selected]} />
                    </div>
                  </Example>
                  <Button
                    color='positive'
                    size='large'
                    onClick={() => {
                      setPhase(1);
                      setInputStr('');
                    }}
                  >
                    問題を解く
                  </Button>
                </div>
              </div>
            </SetSelect>
          </Screen>
          <Screen>
            <Desc>
              <SubButton
                color='positive'
                size='medium'
                onClick={() => {
                  setPhase(0);
                  setQusetion(0);
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                問題セットの選択
              </SubButton>
              <p>問題を解きましょう</p>
            </Desc>
            <SetSelect>
              <Problems>
                {stages[selected].map((single, i) => {
                  return (
                    <Problem
                      key={`problem-${i}`}
                      selected={question == i}
                      onClick={() => {
                        setQusetion(i);
                        setInputStr('');
                        setQusetionOpacity(0);
                        setTimeout(() => {
                          setQusetionOpacity(1);
                        }, 1);
                      }}
                    >
                      <div>{i + 1}</div>
                      <img src={single.image} />
                      {state[selected].finished[i] && (
                        <Answered>
                          <img src={AnsweredImg} />
                        </Answered>
                      )}
                    </Problem>
                  );
                })}
              </Problems>
              <ProblemScreenWrapper>
                <ProblemScreen>
                  <ProblemImage>
                    <div>
                      <div>{question + 1}</div>
                      <img
                        src={stages[selected][question].image}
                        style={{
                          opacity: questionOpacity,
                          transition: questionOpacity == 1 ? '1s' : '0s',
                        }}
                      />
                    </div>
                  </ProblemImage>
                  <TextWindow bracket style={{ width: '520px' }}>
                    {selected == 0 && <>？に入る数字を答えてください。</>}
                    {selected == 1 && (
                      <>？に入るアルファベットを答えてください。</>
                    )}
                    {selected == 2 && (
                      <>Bに入るものを選択肢の中から選んでください。</>
                    )}
                    {selected == 3 && (
                      <>？に入るものを選択肢の中から選んでください。</>
                    )}
                  </TextWindow>
                  <Answer>
                    {stages[selected][question].choice != null && (
                      <ChoicesWrapper>
                        <Choices
                          style={{
                            maxWidth:
                              stages[selected][question].choice?.length == 4
                                ? 280
                                : 400,
                          }}
                        >
                          {stages[selected][question].choice?.map(
                            (single, c) => {
                              return (
                                <SingleChoice
                                  key={`choice-${c}`}
                                  disabled={state[selected].finished[question]}
                                  check={
                                    (state[selected].finished[question]
                                      ? state[selected].answers[question]
                                      : inputStr) == single
                                  }
                                  onClick={() => {
                                    setInputStr(single);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCheck} />
                                  <p>{single}</p>
                                </SingleChoice>
                              );
                            }
                          )}
                        </Choices>
                        <SendButton
                          answered={state[selected].finished[question]}
                          disabled={inputStr == ''}
                          onReset={reset}
                          onSend={send}
                        />
                      </ChoicesWrapper>
                    )}
                    {stages[selected][question].choice == null && (
                      <>
                        <Input
                          value={
                            state[selected].finished[question]
                              ? state[selected].answers[question]
                              : inputStr
                          }
                          placeholder='入力してください'
                          disabled={state[selected].finished[question]}
                          onChange={(t: string) => {
                            setInputStr(t);
                          }}
                          onKeyDown={handleKeyDown}
                        />
                        <SendButton
                          answered={state[selected].finished[question]}
                          disabled={inputStr == ''}
                          onReset={reset}
                          onSend={send}
                        />
                      </>
                    )}
                  </Answer>
                </ProblemScreen>
              </ProblemScreenWrapper>
            </SetSelect>
          </Screen>
        </Screens>
      </Wrapper>
    </CommonBG>
  );
};

const Choices = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ChoicesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 0px;
  margin-bottom: 0px;

  > button {
    margin-bottom: 22px;
  }
`;

interface ChoiceProps {
  check: boolean;
  disabled: boolean;
}

const SingleChoice = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  color: white;
  background: #868e96;
  margin: 0 5px;
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 4px;
  padding-bottom: 6px;
  box-shadow: 0 6px 0 #343a40;

  svg {
    margin: 0 20px;
    font-size: 2.4rem;
    opacity: 0.1;
  }

  p {
    flex: 1;
    padding-right: 20px;
  }

  &:active {
    transform: translateY(6px);
    box-shadow: 0 0px 0 #343a40;
  }

  ${(c: ChoiceProps) =>
    c.check
      ? `
      background: #189BF2;
      box-shadow: 0 6px 0 #185DD0;
      svg {
        opacity: 1.0;
      }
      &:active {
        box-shadow: 0 0px 0 #185DD0;
      }
  `
      : ``}

  ${(c: ChoiceProps) =>
    c.disabled
      ? `
        opacity: 0.5;
        pointer-events: none;
      `
      : ``}
`;

const Answered = styled.div`
  background: rgba(52, 58, 64, 0.75);
  width: 100%;
  height: 100%;

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 180px !important;
    height: 40px !important;
  }
`;

const ProblemScreenWrapper = styled.div`
  position: relative;
  margin-left: 400px !important;

  width: calc(100% - 400px) !important;
`;

const ProblemScreen = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const ProblemImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;

  > div {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border: 1px solid #ced4da;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    }

    > div:first-child {
      z-index: 2;
      position: absolute;
      left: 10px;
      top: 10px;
      background: #343a40;
      color: white;
      font-weight: bold;
      font-size: 2.6rem;
      width: 5.7rem;
      height: 5.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Problems = styled.ul`
  position: fixed;
  height: calc(100% - 109px);
  width: 400px;
  background: rgba(206, 212, 218, 0.25);
  overflow-y: scroll;
`;

const Problem = styled.li`
  cursor: pointer;
  list-style: none;
  width: 320px;
  position: relative;
  margin: 10px auto;
  border: 3px solid white;
  box-sizing: border-box;
  background: white;

  img {
    width: 100%;
    height: auto;
  }

  div:first-child {
    position: absolute;
    left: 5px;
    top: 5px;
    background: #343a40;
    color: white;
    font-weight: bold;
    font-size: 1.6rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(p: ButtonProps) => `
    ${
      p.selected
        ? `
      border: 3px solid #189BF2;
    `
        : ``
    }
  `}
`;

const Answer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;

  margin-top: 30px;
  padding-bottom: 30px;

  input {
    margin-top: 10px;
    height: 60px;
    font-size: 2.6rem;
    border-width: 3px;
  }

  button {
    margin-left: 20px;
    min-width: 120px !important;
  }
`;

interface ScreenProps {
  phase: number;
}

const Screens = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;

  > div {
    overflow-y: hidden;
  }

  ${(p: ScreenProps) => `
    > div:first-child {
      left: ${-p.phase * 100}vw;
    }
    > div:nth-child(2) {
      left: ${-(p.phase - 1) * 100}vw;
    }
  `}
`;

const Screen = styled.div`
  position: absolute;
  top: 0;
  transition: 0.4s;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
`;

const TextWindow = styled(_TextWindow)`
  font-size: 2rem !important;
  line-height: 2.4rem !important;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: initial !important;
  min-height: initial !important;
  padding: 1.2rem 3rem;

  margin-left: auto;
  margin-right: auto;

  p {
    margin-bottom: 10px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const Example = styled.div`
  display: flex;
  margin: 20px auto;
  max-width: 600px;

  > div {
    display: flex;
    justify-content: center;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
      border: 1px solid #ced4da;
    }
  }
`;

const Desc = styled.div`
  position: fixed;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  text-align: center;
  font-size: 2rem;
  padding-top: 60px;
  padding-bottom: 20px;
  font-weight: bold;

  button {
    position: absolute !important;
    left: 20px;

    svg {
      margin-right: 10px;
    }
  }
`;

const SetSelect = styled.div`
  height: calc(100% - 89px);
  display: flex;
  position: relative;
  top: 109px;

  > div {
    position: absolute;
    overflow-y: scroll;
  }

  > div:first-child {
    height: calc(100% - 20px);

    top: 0;
    left: 0;

    width: 40%;
    background: rgba(206, 212, 218, 0.25);

    display: flex;
    flex-wrap: wrap;
  }
  > div:last-child {
    height: calc(100% - 20px);

    overflow: scroll;

    top: 0;
    right: 0;

    width: 60%;

    flex: 1;

    margin: 0 auto;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    > div {
      margin: 20px;
    }
  }
`;

interface ButtonProps {
  selected: boolean;
}

const SingleSet = styled.div`
  cursor: pointer;
  width: calc(50% - 16px);
  margin: 5px 5px;
  border-radius: 10px;
  background: white;
  transition: 0.2s;

  > div:first-child {
    p {
      position: relative;
      left: 5px;
      top: 5px;
      background: #343a40;
      color: white;
      font-weight: bold;
      font-size: 2.6rem;
      width: 3.6rem;
      height: 3.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  :hover {
    box-shadow: 0 0 0 #adb5bd;
    transform: translateY(3px);
  }

  :active {
    opacity: 0.6;
  }

  ul {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 44px;
  }

  ${(p: ButtonProps) =>
    p.selected
      ? `
      border: 3px solid #189BF2;
      box-shadow: 0 3px 0 #189BF2;

      p {
        background: #189BF2 !important;
      }
    `
      : `
      border: 3px solid #adb5bd;
      box-shadow: 0 3px 0 #adb5bd;
    `}

  @media screen and (max-width:1100px) {
    flex: 1;
    width: 100%;
  }
`;

interface DotProps {
  finished: boolean;
}

const Dot = styled.li`
  margin: 0 5px;
  width: 44px;
  height: 44px;
  list-style: none;
  background: #ced4da;
  border-radius: 99px;
  font-size: 2.6rem;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(p: DotProps) =>
    p.finished
      ? `
    background: #7CD6F7;
    div {
      color: rgba(255,255,255,0.3);
    }
  `
      : ``}

  svg {
    position: absolute;
    color: white !important;
  }
`;

const ButtonImg = styled.div`
  margin: 10px 0;
  height: calc(100% - 120px);
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;

  img {
    user-drag: none;
  }
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  display: block;
  margin: 0 auto;
  width: 300px;
  margin-bottom: 20px;
`;

export default Game;
