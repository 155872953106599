import Question_A1 from '../static/jpg/1-1.jpg';
import Question_A2 from '../static/jpg/1-2.jpg';
import Question_A3 from '../static/jpg/1-3.jpg';
import Question_A4 from '../static/jpg/1-4.jpg';
import Question_B1 from '../static/jpg/2-1.jpg';
import Question_B2 from '../static/jpg/2-2.jpg';
import Question_B3 from '../static/jpg/2-3.jpg';
import Question_B4 from '../static/jpg/2-4.jpg';
import Question_C1 from '../static/jpg/3-1.jpg';
import Question_C2 from '../static/jpg/3-2.jpg';
import Question_C3 from '../static/jpg/3-3.jpg';
import Question_C4 from '../static/jpg/3-4.jpg';
import Question_D1 from '../static/jpg/4-1.jpg';
import Question_D2 from '../static/jpg/4-2.jpg';
import Question_D3 from '../static/jpg/4-3.jpg';
import Question_D4 from '../static/jpg/4-4.jpg';

// 基準（0,0）は左上

export interface SingleStage {
  stage: number;
  comment: string;
  answer: string;
  image: string;
  choice?: string[];
  rate?: number;
}

export const stages: SingleStage[][] = [
  [
    {
      stage: 1,
      comment: '周りで接している図形の数を表している。',
      image: Question_A1,
      answer: '4',
    },
    {
      stage: 2,
      comment: '図形の頂点の数を表している。',
      image: Question_A2,
      answer: '8',
    },
    {
      stage: 3,
      comment: '図形の中の点線の本数を表している。',
      image: Question_A3,
      answer: '4',
    },
    {
      stage: 4,
      comment: '縦横の同じ列にある〇の数を表している。',
      image: Question_A4,
      answer: '0',
    },
  ],
  [
    {
      stage: 1,
      comment: '左右対称な文字が並んでいる。',
      image: Question_B1,
      answer: 'T',
    },
    {
      stage: 2,
      comment: '4の倍数番目の文字が並んでいる。',
      image: Question_B2,
      answer: 'D',
    },
    {
      stage: 3,
      comment: '一筆書きで書くことのできない文字が並んでいる。',
      image: Question_B3,
      answer: 'A',
    },
    {
      stage: 4,
      comment: '今までの問題で登場していないアルファベットが並んでいる。',
      image: Question_B4,
      answer: 'Z',
    },
  ],
  [
    {
      stage: 1,
      comment: 'Aは十二支、Bは十二星座に含まれる動物。',
      image: Question_C1,
      answer: 'エ',
      choice: ['ア', 'イ', 'ウ', 'エ'],
    },
    {
      stage: 2,
      comment: 'Bはよく見るとイラストに間違った場所がある。',
      image: Question_C2,
      answer: 'ウ',
      choice: ['ア', 'イ', 'ウ', 'エ'],
    },
    {
      stage: 3,
      comment: 'Aの言葉には動物が、Bの言葉には植物が隠れている。',
      image: Question_C3,
      answer: 'ア',
      choice: ['ア', 'イ', 'ウ', 'エ'],
    },
    {
      stage: 4,
      comment: 'Aは3色、Bは2色で描かれている。',
      image: Question_C4,
      answer: 'ウ',
      choice: ['ア', 'イ', 'ウ', 'エ'],
    },
  ],
  [
    {
      stage: 1,
      comment: 'マスの形がサイコロになっており、縦の列で足し算をしている。',
      image: Question_D1,
      answer: '①',
      choice: ['①', '②', '③', '④', '⑤', '⑥'],
    },
    {
      stage: 2,
      comment: 'キーボード配列の１～９に書かれた記号を表している。',
      image: Question_D2,
      answer: '⑤',
      choice: ['①', '②', '③', '④', '⑤', '⑥'],
    },
    {
      stage: 3,
      comment: '上の列と下の列を合わせると真ん中の文字になる。',
      image: Question_D3,
      answer: '①',
      choice: ['①', '②', '③', '④', '⑤', '⑥'],
    },
    {
      stage: 4,
      comment:
        '真ん中の「Ｇ」を左回りの矢印としてとらえると、「YESTERDAY」という単語が完成する。',
      image: Question_D4,
      answer: '⑤',
      choice: ['①', '②', '③', '④', '⑤', '⑥'],
    },
  ],
];
