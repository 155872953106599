import { TrainingRouter } from './TrainingRouter';
import EventsRouter from './components/eventProvider/EventsRouter';
import AuthErrorPage from './components/eventProvider/common/AuthErrorPage';
import RedirectPage from './components/eventProvider/common/RedirectPage';
import AuthStateListener from './components/global/AuthStateListener';
import LoadingOverlay from './components/global/overlays/loading/LoadingOverlay';
import Store from './redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

export const App = () => {
  return <Router />;
};

export default App;

const Router = () => {
  return (
    <Provider store={Store}>
      <LoadingOverlay />
      <AuthStateListener />
      <BrowserRouter>
        <Routes>
          <Route path='/auth-error' element={<AuthErrorPage />} />
          <Route path='/events/*' element={<EventsRouter />} />
          <Route path='/:trainingId' element={<TrainingRouter />} />
          <Route path='/:trainingId/*' element={<TrainingRouter />} />
          <Route path='/' element={<RedirectPage />} />
          <Route path='/*' element={<Navigate to='/' />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
